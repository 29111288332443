import { useEffect } from 'react';
import Navbar from '../component/navbar'
import Footer from '../component/footer'
import InputMask from 'react-input-mask';
import logoGolWhite from '../img/logo-golplus-white.svg'
import arrowImg from '../img/arrow.svg';
import lineDetail from '../img/line-1.svg';
import section3Top from '../img/section3top.svg';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBBtn, } from 'mdb-react-ui-kit';
import '../index.css';
import { GiBandit } from "react-icons/gi";
import { FaCarCrash } from "react-icons/fa";
import { MdCarCrash } from "react-icons/md";
import { GiBurningEmbers } from "react-icons/gi";
import { MdFlood } from "react-icons/md";
import { GiBrazil } from "react-icons/gi";
import { FaCarOn } from "react-icons/fa6";
import { FaUserShield } from "react-icons/fa";
import { GiCarDoor } from "react-icons/gi";
import { GiTowTruck } from "react-icons/gi";
import { GiThreeKeys } from "react-icons/gi";
import { GiCarWheel } from "react-icons/gi";
import { FaTaxi } from "react-icons/fa";
import { FaHotel } from "react-icons/fa";
import { BiSolidCarGarage } from "react-icons/bi";
import { FaWhatsapp } from "react-icons/fa";

function Index() {
    useEffect(() => {
        document.title = "Gol Plus ABC"
        window.scrollTo(0, 0);

    }, [])

    return (
        <div>
            <MDBContainer id='Home' fluid className="headerContainer" style={{ backgroundImage: 'linear-gradient(to right, #ff6900 40%, #FFC077)', minHeight: '100vh', }}>
                {/* <MDBRow>
                    <MDBCol md={12} style={{ padding: '2rem', textAlign: 'center', marginBottom: '2.5rem' }}>
                        <img src={logoGolWhite} width={'250px'} />
                    </MDBCol>
                </MDBRow> */}
                <MDBRow className="d-flex justify-content-center align-items-center" style={{paddingTop:'7vh'}}>
                    <MDBCol md={6} style={{ textAlign: 'center', maxWidth: '700px' }}>
                        <div className='header-info-container'>
                            <img src={logoGolWhite} width={'300px'} style={{paddingBottom:'10vh'}}/>
                            <h1 className='header-title'>
                                Proteção veicular completa é com a GOL Plus ABC
                            </h1>
                            <h2 style={{ color: '#fbfbfb', fontWeight: '600', fontSize: '1rem', textAlign: 'justify', lineHeight: '1.5rem' }} className='mt-3'>
                                A GOL Plus se move pelo compromisso de cuidar daquilo que você valoriza. Seja seu carro, moto ou bike. Em um mundo repleto de imprevistos, nossa missão é proporcionar a proteção e tranquilidade que você precisa.
                            </h2>
                        </div>
                    </MDBCol>
                    <MDBCol md={5}>
                        <MDBCard className="headerForm hover-shadow" id='contato'>
                            <MDBCardBody>
                                <h2 className='title-form'>Faça sua cotação conosco!</h2>
                                <p className='desc-form'>Informe seus dados abaixo que nossos consultores entrarão em contato com você.</p>
                                <label className='form-label'>Nome</label>
                                <input style={{ borderRadius: '5px', width: '100%', border: '0' }} className='form-control' placeholder='Seu nome' type='text' name='nome'></input>
                                <label className='form-label'>Insira seu E-mail</label>
                                <input style={{ borderRadius: '5px', width: '100%', border: '0' }} className='form-control' placeholder='Ex. gol@gmail.com' type='text' name='nome'></input>
                                <label className='form-label'>Insira sua Cidade</label>
                                <input style={{ borderRadius: '5px', width: '100%', border: '0' }} className='form-control' placeholder='Ex. São Paulo, São Bernardo, etc' type='text' name='nome'></input>
                                <label className='form-label'>Insira seu Whatsapp</label>
                                <InputMask style={{ borderRadius: '5px', width: '100%', border: '0' }} className='form-control' mask={'(99) 9 9999-9999'} placeholder='(00) 00000-0000' type='text' name='nome'></InputMask>
                                <a id='cotacao' className='mt-3 headerBtn hover-shadow'>Faça sua cotação!</a>
                                <p className='privacy-text'>Ao clicar em "Faça sua cotação!" você concorda com nossa política de privacidade.</p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
                <span className='header-arrow hiddenMobile'><a href='#Servicos'><img src={arrowImg}/></a></span>
            </MDBContainer>
            <div style={{ height: '70px' }}></div>
            <MDBContainer id='Servicos'>
                <h1 style={{ color: '#1e1e1e', fontWeight: '800', fontSize: '2rem', textTransform: 'uppercase', textAlign: 'center' }}>
                    Temos o serviço ideal para você
                </h1>
                <p style={{ color: '#242424', fontWeight: 600, fontSize: '1.1rem', textAlign: 'center', marginBottom: '2rem' }}>
                    Conte com uma cobertura veicular completa que não te deixa na mão na hora que você mais precisa!
                </p>
                <MDBRow className='cardsHolder justify-content-center align-items-center'>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '15px', border: '0.2rem solid #1e1e1e' }}><GiBandit style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Furto / Roubo
                                </h3>
                                <p>
                                    Seja ressarcido em até 100% do valor de tabela FIPE caso seu veículo seja roubado ou furtado.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><FaCarCrash style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Colisão
                                </h3>
                                <p>
                                    Em caso de acidente, nós providenciamos o conserto do seu veículo.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><MdCarCrash style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Perda total
                                </h3>
                                <p>
                                    Se o estrago configurar Perda Total, nós iremos indenizá-lo por este prejuízo.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><GiBurningEmbers style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Incêndio
                                </h3>
                                <p>
                                    Com a gente o seu veículo fica protegido em casos de incêndio com indenização total ou parcial.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><MdFlood style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Fenômenos Naturais
                                </h3>
                                <p>
                                    Se seu veículo for danificado por alagamentos, quedas de árvores ou chuvas de granizo, nós ressarcimos seu prejuízo.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><GiBrazil style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Cobertura em todo Brasil
                                </h3>
                                <p>
                                    Não importa onde aconteça o evento, você poderá contar conosco em todo o Brasil.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><FaCarOn style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Carro reserva
                                </h3>
                                <p>
                                    Disponha um carro reserva caso aconteça algum imprevisto e o seu precise ir para a oficina.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><FaUserShield style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Proteção para terceiros
                                </h3>
                                <p>
                                    Caso você se envolva em um acidente com outro veículo, os consertos são por nossa conta.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol md='4'>
                        <MDBCard className='cardsProtec'>
                            <MDBCardBody>
                                <div style={{ backgroundColor: '#fbfbfb', width: '64px', height: '64px', marginTop: '-55px', borderRadius: '50px', marginBottom: '25px', border: '0.2rem solid #1e1e1e' }}><GiCarDoor style={{ width: '100%', height: '100%', padding: '10px', color: '#ff6900' }} /></div>
                                <h3>
                                    Cobertura para vidros
                                </h3>
                                <p>
                                    Caso aconteça algum dano com seus vidros, podemos substituí-lo por outro novinho.
                                </p>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
            <div className='line-detail'><img src={lineDetail}/></div>
            <h1 style={{ marginTop: '35px',marginBottom:'50px' , color: '#1e1e1e', fontWeight: '800', fontSize: '2rem', textTransform: 'uppercase', textAlign: 'center' }}>
                    Serviços 24h em todo território nacional
            </h1>
            <div className='line-detail'><img src={section3Top}/></div>
            <MDBContainer style={{margin:'0', backgroundColor:'#191919', padding:'10%', paddingTop:'5%', maxWidth:'100%'}}>
                <div className='circles-holder'>
                <MDBRow style={{textAlign:'center' }} className='justify-content-center align-items-center'>
                    <MDBCol>
                        <span className='icon-span mb-3'>
                            <GiTowTruck size={'3rem'} color='#f5f5f5' />
                        </span>
                        <h4>Reboque</h4>
                    </MDBCol>
                    <MDBCol>
                        <span className='icon-span mb-3'>
                            <GiThreeKeys size={'3rem'} color='#f5f5f5' />
                        </span>
                        <h4>Chaveiro</h4>
                    </MDBCol>
                    <MDBCol>
                        <span className='icon-span mb-3'>
                            <GiCarWheel size={'3rem'} color='#f5f5f5' />
                        </span>
                        <h4>Troca de Pneus</h4>
                    </MDBCol>
                    <MDBCol>
                        <span className='icon-span mb-3'>
                            <FaTaxi size={'3rem'} color='#f5f5f5' />
                        </span>
                        <h4>Táxi</h4>
                    </MDBCol>
                    <MDBCol>
                        <span className='icon-span mb-3'>
                            <FaHotel size={'3rem'} color='#f5f5f5' />
                        </span>
                        <h4>Hospedagem</h4>
                    </MDBCol>
                    <MDBCol>
                        <span className='icon-span mb-3'>
                            <BiSolidCarGarage size={'3rem'} color='#f5f5f5' />
                        </span>
                        <h4>Guarda do Veiculo</h4>
                    </MDBCol>
                </MDBRow>
                </div>
                <MDBRow className='justify-content-center align-items-center'>
                    <MDBCol className='text-center contact-col'>
                        <a id='whatsappCotacao' href='#contato' className='contactBtn hover-shadow'>Quero fazer minha cotação! <FaWhatsapp style={{fontSize:'2rem'}}/></a>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

            <Footer />
            <a id='whatsappFloat' href="https://wa.me/5511999999999" class="whatsapp-float hover-shadow" target="_blank">
                <FaWhatsapp />
            </a>
        </div>
    )
}

export default Index;